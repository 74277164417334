<template>
  <!--<router-link style="display: block;width: fit-content;padding: 20px 50px;background-color: black;margin: auto;color: white" :to="{name : 'image-compress-ar'}">Image Compresser</router-link>
  <router-link style="display: block;width: fit-content;padding: 20px 50px;background-color: black;margin: auto;color: white" :to="{name : 'background-removal-en'}">Background Removal</router-link>-->
  <div class="container mt-5">
    <div v-if="currentLanguage == 'ar'">
      <div class="text-center mt-3">
          <a href="https://splendapp.com/ar/" target="_blank">
              <img src="@/assets/AR/Gif/1.gif" alt="banner" class="image">
          </a>
      </div>
      <tools-comar ></tools-comar>
      <div class="text-center mt-3 mb-4">
        <a href="https://splendapp.com/ar/" target="_blank">
            <img src="@/assets/AR/Gif/2.gif" alt="banner" class="image">
        </a>
      </div>
    </div>
    <div v-else-if="currentLanguage == 'en'">
      <div class="text-center mt-3">
          <a href="https://splendapp.com/en/" target="_blank">
            <img src="@/assets/EN/3.gif" alt="banner" class="image">
          </a>
      </div>
      <tools-comar ></tools-comar>
      <div class="text-center mt-3 mb-4">
        <a href="https://splendapp.com/en/" target="_blank">
          <img src="@/assets/EN/google AD banner 1 -EN.png" alt="banner" class="image">
        </a>
      </div>
    </div>
  </div>
  

</template>

<script>
import ToolsComen from '@/components/ToolsComen.vue';
import ToolsComar from '@/components/ToolsComar.vue';

export default {
  components: { ToolsComen, ToolsComar },
  data() {
    return {
      currentLanguage: this.$store.state.currentLanguage,
    }
  },
}
</script>

<style>

</style>