<template>
<div>
  <NavBar/>
  <router-view/>
  <Footer/>
  <div class="modal-pop" :style="{
    'display' : show== 'yes'? 'flex' : 'none',
  }" style="position: fixed;top: 0;left: 0;justify-content: center;align-items: center;width: 100vw;height: 100vh;">
    <div class="modal-pop-body" :style="{
      'flex-direction': currentLanguage == 'ar' ? 'row' : 'row-reverse',
    }" style="width: 50%;height: 70vh;z-index: 3;position: relative;display: flex;">
      <div class="close-modal-pop" style="position: absolute;right: 5px;top: 5px;">
        <svg @click="closePopup" style="border: 1px solid;border-radius: 50%;background-color: black;width: 20px;height: 20px;fill: white;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
      </div>
      <div class="left" style="width: 50%;height: 100%;background-color: #3a7afe;display: flex;justify-content: center;align-items: center;">
        <img style="width: 100%;" src="./assets/pop-image.png" alt="">
      </div>
      <div class="right" style="background-color: white;width: 50%;height: 100%;padding: 70px 30px 20px;text-align: center;padding-top: 70px;">
        <h3 style="font-size: 28px;font-weight: bold;">{{ formdata[currentLanguage].title }}</h3>
        <p style="margin-top: 10px;font-size: 20px;margin-bottom: 50px;">{{ formdata[currentLanguage].desc }}</p>
        <a :href="formdata[currentLanguage].url" style="font-size: 20px;font-weight: 700; text-decoration: none;width: 100%;padding: 10px;border-radius: 7px;background-color: #3a7afe;color: white;display: block;">{{ formdata[currentLanguage].button }}</a>
        <img style="width: 65%;margin-top: 20px;margin-bottom: 15px;" src="./assets/logo2.png" alt="">
        <p>{{ formdata[currentLanguage].att }}</p>
      </div>
    </div>
    <div class="modal-pop-overlay" style="width: 100vw;height: 100vh;background-color:#77777787;z-index: 1;position: absolute;top: 0;left: 0;">

    </div>
  </div>
</div>
</template>

<script>
import 'bootstrap';
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
export default {
  data() {
    return {
      currentLanguage: 'en',
      show: 'no',
      formdata: {
        ar: {
          title: 'ضاعف مبيعاتك مع تطبيق احترافي لمتجرك!',
          desc: 'حوّل زوار متجرك إلى عملاء مخلصين مع تطبيق احترافي خلال 48 ساعة فقط!',
          button: 'احصل علي تطبيقك الآن',
          att: 'لا تفقد الفرصة – عملاؤك ينتظرون تطبيقك!',
          url : 'https://splendapp.com/ar/'
        },
        en: {
          title: 'Double Your Sales with a Professional Mobile App!',
          desc: 'Turn Your e-Store into a Mobile App in 48 Hours',
          button: 'Get Your App Now',
          att: 'Don’t miss out – your customers are waiting for your app!',
          url : 'https://splendapp.com/en/'
        }
      }
    }
  },
  components: {
    NavBar,
    Footer
  },
  watch: {
    $route() {
      this.showPopup();
    }
  },
  mounted() {
    this.showPopup();
  },
  methods: {
    showPopup(){
      if(this.$store.state.currentLanguage == 'en'){
      this.currentLanguage = 'en'
    } else if(this.$store.state.currentLanguage == 'ar'){
      this.currentLanguage = 'ar'
    }
    console.log('lang', this.currentLanguage)
    setTimeout(()=>{
      this.show= 'yes'
    }, 10000)
    },
    closePopup(){
      this.show= 'no'
    }
  }
}
</script>
<style lang="scss">
:root{
  --main-color: #24365c;
}
#app {
  font-family: 'Cairo', sans-serif;
}
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
}
@media (max-width: 700px) {
  .modal-pop-body{
    flex-direction: column-reverse !important;
    width: 90% !important;
    height: 91% !important;
  }
  .modal-pop-body > div.left{
    width: 100% !important;
    height: 40% !important;
  } 
  .modal-pop-body > div.right{
    width: 100% !important;
    height: 60% !important;
  } 
  .modal-pop-body > div:nth-of-type(3){
    padding: 70px 20px 20px !important;
  }
  .modal-pop-body > div:nth-of-type(3) a{
    margin-bottom: 20px !important;
  }
  .modal-pop-body > div:nth-of-type(3) img{
    margin-bottom: 30px !important;
  }
}
@import url('./assets/styles/bootstrap.min.css');
@import url('./assets/styles/all.min.css');
</style>
